<template>
  <v-list class="hive-sidebar" dense>
    <template v-for="(item, i) in sbItems">
      <!-- Divider -->
      <v-divider v-if="item.divider" :key="i" dark class="my-0"></v-divider>

      <!-- URL to Legacy BNSHive -->
      <v-list-item v-else-if="item.url" :key="item.text" :href="item.url" link>
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- URL to Legacy BNSHive Tools -->
      <v-list-group
        v-else-if="item.type == 'navTools'"
        :key="item.text"
        v-model="item.model"
        :prepend-icon="item.icon"
        :append-icon="item.model ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="(tool, i) in item.children"
          :key="i"
          :to="tool.path"
          link
        >
          <v-list-item-action v-if="tool.icon">
            <v-icon>{{ tool.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ tool.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- Class Selection List -->
      <v-list-group
        class="hive-selClassTab"
        v-else-if="item.type == 'selClass'"
        :key="item.text"
        v-model="item.model"
        :prepend-icon="item.icon"
        :append-icon="item.model ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      >
        <template v-slot:activator>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
          <v-list-item-avatar class="hive-selClassIcon">
            <v-img
              v-bind:src="`${publicPath}img/bnsclass/${selClass}.png`"
              contain
              height="28px"
              width="28px"
              class="hive-bnsClassIcon"
            />
          </v-list-item-avatar>
        </template>
        <v-btn
          v-for="bnsclass in classList"
          :key="bnsclass.name"
          v-on:click="onSelectClass(bnsclass.name)"
          :disabled="bnsclass.disabled"
          icon
          primary
          width="45"
          height="45"
          :class="{
            'hive-bnsClassIconBtn': true,
            selected: bnsclass.name == selClass,
          }"
        >
          <img
            v-bind:src="`${publicPath}img/bnsclass/${bnsclass.name}.png`"
            contain
            height="34px"
            width="34px"
          />
        </v-btn>
      </v-list-group>

      <!-- List with children -->
      <v-list-group
        v-else-if="item.type == 'navCate'"
        :key="item.text"
        v-model="item.model"
        :prepend-icon="item.icon"
        :append-icon="item.model ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="(cate, i) in cateList"
          :key="i"
          :to="'/cate/' + cate.name"
          link
        >
          <v-list-item-action v-if="cate.icon">
            <v-icon>{{ cate.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ cate.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- List without children -->
      <v-list-item v-else :key="item.text" :to="item.href" link>
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list-item>
      <v-icon class="mx-auto py-6">mdi-cat</v-icon>
    </v-list-item>
  </v-list>
</template>

<script>
import BnsClassList from "@/data/BnsClassList.json";
import SidebarCateList from "@/data/SidebarCateList.json";

export default {
  name: "Sidebar",
  props: ["selClass", "selStyle"],
  data: () => ({
    publicPath: process.env.BASE_URL,
    classList: BnsClassList,
    cateList: SidebarCateList,
    sbItems: [
      { icon: "mdi-home", text: "蜂窩首頁", href: "/" },
      {
        type: "selClass",
        icon: "mdi-contacts",
        text: "選擇職業",
        model: false,
      },
      { divider: true },
      {
        icon: "mdi-database-search",
        text: "角色查詢",
        href: "/character",
      },
      {
        type: "navCate",
        icon: "mdi-sword-cross",
        text: "裝備資訊",
        model: false,
      },
      {
        type: "navTools",
        icon: "mdi-apps",
        text: "裝備系統",
        model: false,
        children: [
          {
            name: "裝備共鳴資料",
            path: "/resonance",
          },
          {
            name: "俠義團 / 光輝石資料",
            path: "/unity",
          },
          {
            name: "布拉國石板 / 血石資料",
            path: "/bloodstones",
          },
          {
            name: "印章資料",
            path: "/glyphs",
          },
        ],
      },
      {
        type: "navTools",
        icon: "mdi-calculator",
        text: "工具箱",
        model: false,
        children: [
          {
            name: "拍賣所手續費計算器",
            path: "/auction",
          },
          {
            name: "屬性曲線數值換算器",
            path: "/statscurve",
          },
          {
            name: "自動釣魚程式",
            path: "/fishing",
          },
          {
            name: "蜂窩角色查詢插件",
            path: "/ingame",
          },
          {
            name: "Discord Bot",
            path: "/discordbot",
          },
        ],
      },
      { icon: "mdi-cog", text: "偏好設定", href: "/settings" },
      { icon: "mdi-help-circle", text: "關於蜂窩", href: "/about" },
      {
        icon: "mdi-keyboard-return",
        text: "舊版劍靈蜂窩",
        url: "https://legacy.bnshive.com",
      },
    ],
  }),
  created() {},
  methods: {
    onSelectClass(className) {
      this.$emit("update:selClass", className);

      // Handles classes without style 3
      if (!this.classList[className].hasStyle3 && this.selStyle == 3) {
        this.$emit("update:selStyle", 1);
      }
    },
  },
};
</script>

<style lang="scss">
.hive-sidebar .v-list-item__title {
  font-size: 0.875rem !important;
}
.hive-sidebar .v-list--dense .v-list-item {
  min-height: 36px;
}
.hive-selClassTab {
  .hive-selClassIcon {
    border-radius: 0 !important;
    margin: 0 !important;
  }
  &:not(.v-list-group--active) {
    .hive-bnsClassIcon {
      filter: grayscale(100%);
      .theme--light & {
        filter: invert(85%);
      }
    }
  }
  .v-list-group__header__append-icon {
    margin: 0px !important;
    min-width: 24px !important;
  }
}
.v-list-item .v-list-item__action,
.v-list-group__header .v-list-group__header__prepend-icon {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    alias: "/about/update",
    name: "About",
    meta: { title: "關於蜂窩" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    meta: { title: "偏好設定" },
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue")
  },
  {
    path: "/cate/:cateName",
    name: "Category",
    meta: { title: "分類", dynamicTitle: true },
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/Category.vue")
  },
  {
    path: "/search/:searchName?",
    name: "Search",
    meta: { title: "搜尋", dynamicTitle: true },
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Search.vue")
  },
  {
    path: "/item/:itemId/:lvStr?",
    name: "Item",
    meta: { title: "裝備資訊", dynamicTitle: true },
    component: () => import(/* webpackChunkName: "item" */ "../views/Item.vue")
  },
  {
    path: "/character/:charId?",
    name: "Character",
    meta: { title: "裝備查詢" },
    component: () =>
      import(/* webpackChunkName: "character" */ "../views/Character_v3.vue")
  },
  {
    path: "/resonance/:setId?",
    name: "Resonance",
    meta: { title: "裝備共鳴資料" },
    component: () =>
      import(/* webpackChunkName: "resonance" */ "../views/Resonance.vue")
  },
  {
    path: "/unity",
    alias: ["/unity/stones", "/unity/set"],
    meta: { title: "俠義團/光輝石資料" },
    component: () =>
      import(/* webpackChunkName: "unity" */ "../views/Unity.vue"),
    children: [
      {
        path: "",
        name: "UnityLv",
        meta: { title: "俠義團/光輝石資料" },
        component: () =>
          import(/* webpackChunkName: "unity" */ "../views/UnityLv.vue")
      },
      {
        path: "stones",
        name: "UnityStones",
        meta: { title: "俠義團/光輝石資料" },
        component: () =>
          import(/* webpackChunkName: "unity" */ "../views/UnityStones.vue")
      },
      {
        path: "set",
        name: "UnitySet",
        meta: { title: "俠義團/光輝石資料" },
        component: () =>
          import(/* webpackChunkName: "unity" */ "../views/UnitySet.vue")
      }
    ]
  },
  {
    path: "/bloodstones",
    name: "Bloodstones",
    meta: { title: "布拉國石板/血石資料" },
    component: () =>
      import(/* webpackChunkName: "bloodstone" */ "../views/Bloodstones.vue")
  },
  { path: "/glyph", redirect: "/glyphs" },
  {
    path: "/glyphs",
    name: "Glyphs",
    meta: { title: "印章資料" },
    component: () =>
      import(/* webpackChunkName: "glyph" */ "../views/Glyphs_v2.vue")
  },
  {
    path: "/auction",
    alias: "/auction/details",
    name: "Auction",
    meta: { title: "拍賣所手續費計算器" },
    component: () =>
      import(/* webpackChunkName: "auction" */ "../views/Auction.vue")
  },
  {
    path: "/statscurve",
    alias: "/statscurve/details",
    name: "StatsCurve",
    meta: { title: "屬性曲線數值換算器" },
    component: () =>
      import(/* webpackChunkName: "statscurve" */ "../views/StatsCurve.vue")
  },
  {
    path: "/fishing",
    name: "Fishing",
    meta: { title: "自動釣魚程式" },
    component: () =>
      import(/* webpackChunkName: "fishing" */ "../views/Fishing.vue")
  },
  {
    path: "/ingame",
    name: "Ingame",
    meta: { title: "蜂窩查角插件" },
    component: () =>
      import(/* webpackChunkName: "ingame" */ "../views/Ingame.vue")
  },
  {
    path: "/discordbot",
    name: "DiscordBot",
    meta: { title: "Discord Bot" },
    component: () =>
      import(/* webpackChunkName: "discordbot" */ "../views/DiscordBot.vue")
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "找不到網頁" },
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue")
  },
  {
    path: "*",
    redirect: { name: "404" }
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (!to.meta.dynamicTitle) {
    if (to.meta.title) {
      document.title = to.meta.title + " - 劍靈蜂窩";
    } else {
      document.title = "劍靈蜂窩";
    }
  }
  next();
});

export default router;

<template>
  <div class="hive-view-home">
    <!--v-parallax src="../assets/img/homeBanner3.png" alt="劍靈蜂窩歡迎您"-->
    <v-container
      fluid
      :class="{
        'fill-height': this.$vuetify.breakpoint.mdAndUp ? false : false,
        'py-0': true
      }"
    >
      <v-row align="start" align-content="start">
        <v-col cols="12" class="hive-homeTitle text-center">
          <div
            :style="{
              color: themedTextColor,
              'min-height': '100px',
              'white-space': 'nowrap',
              overflow: 'hidden'
            }"
          >
            <div class="hive-homeHeading" :style="{ color: themedTextColor }">
              <strong>劍靈蜂窩</strong>歡迎您
            </div>
            <div
              class="hive-homeSubheading"
              :style="{ color: themedTextColor }"
            >
              持續更新的劍靈裝備資訊站<br />
              韓服劍靈的最新中文翻譯資訊<br />
              最新裝備資訊．互動式設計．詳盡細節
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2 mb-3">
        <v-col cols="12" md="6" xl="5" class="mx-auto py-0">
          <!-- 最新通知 -->
          <v-card flat outlined class="my-3">
            <v-card-title>
              <v-icon large left>
                mdi-alert-circle-outline
              </v-icon>
              <span class="title font-weight-light">
                通知
                <span class="hive-colSubheader">
                  /{{ latestNotification.date }}
                </span>
              </span>
            </v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text class="pb-0">
              <div class="hive-authorWords">
                {{ latestNotification.text }}
              </div>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-list-item class="grow">
                <v-row align="center" justify="end">
                  <v-btn text :to="latestNotification.btnlink">
                    {{ latestNotification.btntext }}
                  </v-btn>
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>

          <!-- 最近更新 -->
          <v-card flat outlined class="mt-3">
            <v-card-title>
              <v-icon large left>
                mdi-update
              </v-icon>
              <span class="title font-weight-light">
                最近更新
                <span class="hive-colSubheader">
                  /{{ latestUpdate.date }}
                </span>
              </span>
            </v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text class="pb-0">
              <div class="hive-updateItemHeader" style="line-height: 1.625rem">
                {{ latestUpdate.title }}
              </div>
              <div
                v-for="(content, i) in latestUpdate.contents"
                :key="i"
                class="hive-updateItemContent"
              >
                + {{ content.text }}
              </div>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-list-item class="grow">
                <v-row align="center" justify="end">
                  <v-btn
                    text
                    :to="{
                      name: 'About',
                      params: {
                        updateItem: latestUpdate.date
                      }
                    }"
                    >查看詳細</v-btn
                  >
                </v-row>
              </v-list-item>
            </v-card-actions>
            <!-- 最近主要更新(如有) -->
            <template v-if="latestMajorUpdate">
              <v-divider />
              <v-card-title>
                <v-icon large left>
                  mdi-update
                </v-icon>
                <span class="title font-weight-light">
                  最近主要更新
                  <span class="hive-colSubheader">
                    /{{ latestMajorUpdate.date }}
                  </span>
                </span>
              </v-card-title>
              <v-divider class="mx-4"></v-divider>
              <v-card-text class="pb-0">
                <div
                  class="hive-updateItemHeader"
                  style="line-height: 1.625rem"
                >
                  {{ latestMajorUpdate.title }}
                </div>
                <div
                  v-for="(content, i) in latestMajorUpdate.contents"
                  :key="i"
                  class="hive-updateItemContent"
                >
                  + {{ content.text }}
                </div>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-list-item class="grow">
                  <v-row align="center" justify="end">
                    <v-btn
                      text
                      :to="{
                        name: 'About',
                        params: {
                          updateItem: latestMajorUpdate.date
                        }
                      }"
                      >查看詳細</v-btn
                    >
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </template>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" xl="5" class="mx-auto py-0">
          <v-card flat outlined class="mt-3">
            <v-card-title>
              <v-icon large left>
                mdi-account-circle-outline
              </v-icon>
              <span class="title font-weight-light">關於蜂窩</span>
            </v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text class="hive-authorWords pb-0">
              蜂窩是一個非官方的【劍靈】資料網站。<br />蜂窩的主要服務對象為台服劍靈玩家，創立目的是希望方便台服劍靈玩家查詢台服現有及即將推出的最新裝備。最新資料來源主要為韓服及韓測服，並參考台服官方技能譯名來翻譯有關資料。<br />目前蜂窩所有資料來源、翻譯及更新工作都由玉蜂一人進行，因此如遇上資料錯誤等問題請見諒，歡迎以電郵聯繫我，確認後將盡快修正。<br />如對蜂窩有甚麼建議也歡迎聯繫，感謝各位的支持。
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    src="../assets/img/authorIcon.jpg"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>玉蜂</v-list-item-title>
                </v-list-item-content>
                <v-row align="center" justify="end">
                  <v-btn
                    text
                    :to="{ name: 'About', params: { tabName: 'author' } }"
                    >關於玉蜂</v-btn
                  >
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row></v-row>
    </v-container>
    <v-img
      alt="劍靈蜂窩"
      class="hive-bgImage hidden-xs-only"
      src="../assets/img/bgDesktop.jpg"
      :gradient="
        $vuetify.theme.dark
          ? '0deg,rgba(0,0,0,.25) 160px,transparent'
          : '0deg,rgba(255,255,255,.25) 160px,transparent'
      "
    ></v-img>
    <v-img
      alt="劍靈蜂窩"
      class="hive-bgImage hidden-sm-and-up"
      src="../assets/img/bgMobile.jpg"
      :gradient="
        $vuetify.theme.dark
          ? '0deg,rgba(0,0,0,.25) 160px,transparent'
          : '0deg,rgba(255,255,255,.25) 160px,transparent'
      "
    ></v-img>
  </div>
</template>

<script>
import UpdateRecordRaw from "@/data/UpdateRecord.json";
import Notifications from "@/data/Notifications.json";

export default {
  name: "Home",
  components: {},
  data: () => ({}),
  computed: {
    updateRecord: function() {
      var records = UpdateRecordRaw.slice().reverse();
      // Remove template record
      records.shift();
      return records;
    },
    latestUpdate: function() {
      return this.updateRecord[0];
    },
    latestMajorUpdate: function() {
      if (this.latestUpdate.major) {
        return null;
      }
      var majorUpdate = this.updateRecord.find(record => {
        return record.major;
      });
      return majorUpdate;
    },
    latestNotification: function() {
      return Notifications.slice().reverse()[1];
    },
    themedTextColor: function() {
      return this.$vuetify.theme.dark ? "#FFFFFF" : "rgba(0, 0, 0, 0.87)";
    }
  },
  created() {}
};
</script>

<style lang="scss">
.hive-view-home .hive-bgImage {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: unset;
  height: 450px;
  &.hidden-sm-and-up {
    height: 300px;
  }
}
.hive-view-home .hive-homeTitle {
  z-index: 1;
  height: 450px;
  padding-top: 200px !important;
  @media (max-width: 599px) {
    height: 300px;
    padding-top: 120px !important;
  }
}
.hive-view-home .hive-homeHeading {
  font-size: 3.825rem !important;
  font-weight: 300;
  line-height: 5rem;
  letter-spacing: -0.0083333333em !important;
  @media (max-width: 599px) {
    font-size: 2.5rem !important;
    line-height: 3.5rem;
  }
}
.hive-view-home .hive-homeSubheading {
  font-size: 1.425rem !important;
  font-weight: 400;
  line-height: 2.125rem;
  letter-spacing: 0.0073529412em !important;
  @media (max-width: 599px) {
    font-size: 1.0625rem !important;
    line-height: 1.625rem;
  }
}
.hive-view-home .hive-colSubheader {
  font-size: 0.9375rem;
}
.hive-view-home .hive-updateItemHeader {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.016666em;
  padding-bottom: 0.5rem;
}
.hive-view-home .hive-updateItemContent {
  font-size: 0.9375rem;
  line-height: 1.5rem;
}
.hive-view-home .hive-authorWords {
  font-size: 1.0625rem !important;
  line-height: 1.75rem !important;
  letter-spacing: 0.0125em !important;
}
</style>

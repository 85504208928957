<template>
  <v-app id="bnshive-app">
    <v-navigation-drawer
      v-model="sidebar.show"
      :permanent="$vuetify.breakpoint.lgAndUp"
      width="225"
      app
      overflow
    >
      <Sidebar :selClass.sync="selClass" :selStyle.sync="selStyle" />
    </v-navigation-drawer>

    <v-app-bar app id="hive-header" v-if="!ingameView">
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        @click.stop="sidebar.show = !sidebar.show"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        id="hive-title"
        v-if="
          (!showSearchBar && $route.name != 'Search') ||
            $vuetify.breakpoint.smAndUp
        "
        :class="$vuetify.breakpoint.xsOnly ? 'pl-2' : ''"
      >
        <router-link to="/" tag="span">
          劍靈蜂窩
        </router-link>
      </v-toolbar-title>
      <router-link
        v-if="lastUpdateNoti[0] > lastReadUpdateNoti"
        :to="
          lastUpdateNoti[1] == 1
            ? {
                name: 'About',
                params: {
                  updateItem: lastUpdateNoti[0]
                }
              }
            : '/'
        "
        tag="span"
        @click.native="clickedUpdateNotiBtn"
      >
        <v-alert
          id="hive-title-notification"
          dense
          outlined
          type="error"
          :icon="
            $vuetify.breakpoint.smAndUp ? 'mdi-alert-circle-outline' : false
          "
          :text="!$vuetify.theme.dark"
          class="ma-0 ml-2"
          :class="$vuetify.breakpoint.xsOnly ? 'px-2 py-1' : ''"
        >
          <span v-if="lastUpdateNoti[1] == 1">有新更新</span>
          <span v-if="lastUpdateNoti[1] == 2">有新通知</span>
        </v-alert>
      </router-link>
      <v-spacer></v-spacer>
      <v-expand-transition mode="out-in">
        <v-combobox
          v-show="showSearchBar || $route.name == 'Search'"
          ref="searchField"
          :search-input.sync="searchBoxValue"
          :items="itemListArr"
          item-text="name"
          item-value="name"
          no-filter
          filled
          dense
          rounded
          clearable
          hide-details
          hide-no-data
          :autofocus="$route.name != 'Search'"
          placeholder="搜尋角色/裝備"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar v-if="data.item.icon">
                <img :src="itemIconSrc(data.item.icon)" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-if="data.item.cate"
                  v-html="cateList[data.item.cate].text"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-combobox>
      </v-expand-transition>
      <!--v-spacer v-if="$vuetify.breakpoint.smAndUp" /-->
      <v-btn
        icon
        :color="showSearchBar && $route.name != 'Search' ? 'primary' : ''"
        @click.stop="searchBtn()"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <router-view
        id="hive-routerView"
        :selClass.sync="selClass"
        :selStyle.sync="selStyle"
        :showTotopBtn.sync="showTotopBtn"
        :showKRTextDesc.sync="showKRTextDesc"
        :scrolled="scrolled"
        :lastReadUpdateNoti.sync="lastReadUpdateNoti"
      />
    </v-content>

    <v-btn
      fixed
      right
      bottom
      fab
      small
      color="primary"
      v-scroll="onScroll"
      v-if="showTotopBtn && !ingameView"
      v-show="scrolled.yOffset > 20"
      @click="scrollTop()"
      class="hive-toTopBtn"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>

    <!-- IngameView menu -->
    <v-btn
      fixed
      top
      left
      fab
      small
      color="grey darken-3"
      v-scroll="onScroll"
      v-if="ingameView"
      v-show="scrolled.yOffset <= 100"
      @click="sidebar.show = !sidebar.show"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-footer app inset id="hive-footer" class="py-1">
      <span class="pl-4">
        {{ new Date().getFullYear() }} - <strong>玉蜂</strong>
      </span>
      <v-spacer />
      <span class="caption text--secondary">更新：{{ lastUpdateDate }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import ItemList from "@/data/ItemList.json";
import BnsClassList from "@/data/BnsClassList.json";
import ItemCateList from "@/data/ItemCateList.json";
import UpdateRecord from "@/data/UpdateRecord.json";
import Notifications from "@/data/Notifications.json";

export default {
  name: "App",

  components: {
    Sidebar
  },

  data: () => ({
    publicPath: process.env.BASE_URL,
    sidebar: {
      show: false
    },
    selClass: null,
    selStyle: 1,
    showSearchBar: false,
    itemList: ItemList,
    classList: BnsClassList,
    cateList: ItemCateList,
    searchBoxValue: "",
    scrolled: {
      yOffset: 0
    },
    showTotopBtn: localStorage.getItem("hive-showTotopBtn") != "false",
    ingameView: navigator.userAgent.includes("BnsIngameBrowser"),
    showKRTextDesc: null,
    lastReadUpdateNoti: null
  }),
  computed: {
    itemListArr: function() {
      var array = [];
      if (!this.searchBoxValue || this.searchBoxValue == "") {
        return array;
      }
      // CharQuery
      var charSearch = this.searchBoxValue.replace("查詢玩家：", "");
      array.push({
        name: "查詢玩家：" + charSearch
      });
      array.push({ divider: true });

      // Equipment
      array.push({ header: "匹配裝備" });
      // Item search algorithm
      // 1. Exact search on item name and cate name
      let resList = Object.values(this.itemList).filter(
        item =>
          item.name.includes(this.searchBoxValue) ||
          this.cateList[item.cate].text.includes(this.searchBoxValue)
      );
      // 2. If no exact, perform fuzzy search
      if (resList.length === 0) {
        resList = Object.values(this.itemList).filter(item =>
          this.fuzzyMatch(item, this.searchBoxValue)
        );
      }
      // 3. If no result, match exact item ID
      if (resList.length === 0) {
        resList = Object.values(this.itemList).filter(
          item => item.id == this.searchBoxValue
        );
      }
      if (resList.length === 0) {
        resList = [{ header: "沒有任何裝備符合您的搜尋" }];
      }
      array = array.concat(resList);
      return array;
    },
    lastUpdateDate: function() {
      return UpdateRecord.slice().reverse()[1].date;
    },
    lastUpdateNoti: function() {
      var lastUpdate = parseInt(this.lastUpdateDate);
      var lastNoti = parseInt(Notifications.slice().reverse()[1].date);

      var returnDate, returnCate;
      if (lastNoti >= lastUpdate) {
        returnDate = lastNoti;
        returnCate = 2;
      } else {
        returnDate = lastUpdate;
        returnCate = 1;
      }
      var array = [];
      array.push(returnDate);
      array.push(returnCate);
      return array;
    }
  },
  watch: {
    "$vuetify.theme.dark": function() {
      this.updateThemeClass();
    },
    showKRTextDesc: function() {
      this.updateShowKRTextDescClass();
    }
  },
  created() {
    // Initial Webapp settings (Preference settings)
    this.$vuetify.theme.dark =
      localStorage.getItem("hive-nightMode") != "false";

    var initBnsClass = localStorage.getItem("hive-initBnsClass");
    if (initBnsClass && initBnsClass == "td") {
      localStorage.setItem("hive-initBnsClass", "am");
      initBnsClass = "am";
    }
    this.selClass = initBnsClass ? initBnsClass : "bm";

    var initGridDisplay = localStorage.getItem("hive-gridDisplay") != "false";
    sessionStorage.setItem("hive-gridDisplay", initGridDisplay);

    this.showKRTextDesc =
      localStorage.getItem("hive-showKRTextDesc") != "false";

    var lastUpdateNoti = parseInt(
      localStorage.getItem("hive-lastReadUpdateNoti")
    );
    this.lastReadUpdateNoti = Number.isInteger(lastUpdateNoti)
      ? lastUpdateNoti
      : 0;
    localStorage.setItem("hive-lastReadUpdateNoti", this.lastReadUpdateNoti);
  },
  mounted() {
    this.updateThemeClass();

    // Enter key on search bar
    const originalOnKeyDown = this.$refs.searchField.onKeyDown;
    this.$refs.searchField.onKeyDown = e => {
      e.keyCode === 13 ? this.searchItem() : originalOnKeyDown(e);
    };
  },
  methods: {
    searchBtn() {
      if (this.$route.name == "Search") {
        this.searchItem();
      } else if (!this.showSearchBar) {
        this.searchBoxValue = "";
        this.showSearchBar = true;
      } else {
        if (!this.searchBoxValue || this.searchBoxValue == "") {
          this.showSearchBar = false;
        } else {
          this.searchItem();
        }
      }
    },
    searchItem() {
      if (!this.searchBoxValue || this.searchBoxValue == "") {
        this.showSearchBar = false;
      } else if (this.searchBoxValue.indexOf("查詢玩家：") != -1) {
        var charName = this.searchBoxValue.replace("查詢玩家：", "");
        if (charName != this.$route.params.charId) {
          this.$router.push({
            name: "Character",
            params: { charId: charName }
          });
        }
      } else if (this.searchBoxValue != this.$route.params.searchName) {
        this.$router.push({
          name: "Search",
          params: { searchName: this.searchBoxValue }
        });
      }
      this.$refs.searchField.blur();
    },
    itemIconSrc(icon) {
      if (icon.mode == "weaponCode") {
        return (
          this.publicPath +
          icon.prefix +
          this.classList[this.selClass].weaponCode +
          icon.suffix
        );
      } else if (icon.mode == "classCode") {
        return this.publicPath + icon.prefix + this.selClass + icon.suffix;
      } else {
        return this.publicPath + icon;
      }
    },
    fuzzyMatch(item, queryStr) {
      let charArray = item.name.split("");
      charArray = charArray.concat(this.cateList[item.cate].text.split(""));
      let queryArr = queryStr.toLowerCase().split("");
      // Return all char in queryArr exist in charArray
      return (
        queryArr.filter(function(each) {
          return charArray.indexOf(each) === -1;
        }).length === 0
      );
    },
    updateThemeClass() {
      var isDark = this.$vuetify.theme.dark;
      var addClass = isDark ? "theme--dark" : "theme--light";
      var removeClass = isDark ? "theme--light" : "theme--dark";
      document.body.classList.add(addClass);
      document.body.classList.remove(removeClass);
    },
    updateShowKRTextDescClass() {
      if (!this.showKRTextDesc) {
        document.body.classList.add("hideKRTextDesc");
      } else {
        document.body.classList.remove("hideKRTextDesc");
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      this.scrolled.yOffset = window.pageYOffset || e.target.scrollTop || 0;
    },
    scrollTop() {
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    },
    clickedUpdateNotiBtn() {
      this.lastReadUpdateNoti = this.lastUpdateNoti[0];
      localStorage.setItem("hive-lastReadUpdateNoti", this.lastReadUpdateNoti);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
@import "~vuetify/src/styles/settings/_variables";

.v-application p {
  margin: 0 !important;
}
#hive-title {
  cursor: default;
  overflow: unset !important;
  text-overflow: unset !important;
}
#hive-title-notification {
  cursor: pointer;
}
.v-menu__content.v-autocomplete__content .v-list--dense .v-list-item {
  .v-list-item__title {
    font-size: 0.9375rem;
  }
  .v-list-item__subtitle {
    font-size: 0.875rem;
  }
}
.v-menu__content.v-autocomplete__content .v-list--dense .v-subheader {
  font-size: 0.9375rem;
  padding: 0 12px;
}
#hive-routerView {
  height: 100%;
}

// Scrollbar Themes
html {
  overflow-y: overlay !important;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-button:start {
  height: 64px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 56px;
  }
}
::-webkit-scrollbar-button:end {
  height: 32px;
}
.theme--dark {
  &::-webkit-scrollbar-track {
    background: transparent;
    background: #202020;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(64, 64, 64, 0.5);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(96, 96, 96, 0.5);
  }
}
.theme--light {
  &::-webkit-scrollbar-track {
    background: transparent;
    background: #e6e6e6;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(192, 192, 192, 0.5);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(96, 96, 96, 0.5);
  }
}
// Hide scrollbar in sidebar
.v-navigation-drawer ::-webkit-scrollbar {
  display: none;
}

.hive-toTopBtn {
  bottom: 48px !important;
}
</style>
